<template>
  <div id="app" class="container text-center">
    <h1 class="kaisei">🌵仙人微彩模擬器</h1>
    <div class="grid-container">
      <div class="grid">
        <div v-for="(cell, index) in grid" :key="index" class="cell" 
             @click="handleClick(index)" 
             :class="{ revealed: cell !== 0, selected: selectedCells.includes(index), 'computer-cell': index === revealedCell, 'show-answer': showAll || (lineSelected && !selectedCells.includes(index)) }">
          {{ cell !== 0 ? cell : '' }}
        </div>
      </div>

      <div class="line-buttons kaisei">
        <button class="btn btn-dark" @click="selectLine('row1')">橫第一行</button>
        <button class="btn btn-dark" @click="selectLine('row2')">橫第二行</button>
        <button class="btn btn-dark" @click="selectLine('row3')">橫第三行</button>
        <button class="btn btn-dark" @click="selectLine('col1')">直第一列</button>
        <button class="btn btn-dark" @click="selectLine('col2')">直第二列</button>
        <button class="btn btn-dark" @click="selectLine('col3')">直第三列</button>
        <button class="btn btn-dark" @click="selectLine('diag1')">左上右下</button>
        <button class="btn btn-dark" @click="selectLine('diag2')">左下右上</button>
        <button v-if="!gameStarted" class="btn btn-success" @click="startGame" :disabled="gameStarted">開始遊戲</button>
        <button v-if="lineSelected" class="btn btn-danger" @click="resetGame">重新開始</button>
      </div>
    </div>
    <div v-if="resultMessage" class="mt-3 alert ffalert kaisei">{{ resultMessage }}</div>
  
  </div>

    <div class="container mt-5 kaisei">
      <h4 class="mb-4">獎金列表</h4>
      <table class="table table-striped table-bordered table-dark">
        <thead>
          <tr>
            <th>獲得分數</th>
            <th>獎金</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>6</td>
            <td>10000</td>
          </tr>
          <tr>
            <td>24</td>
            <td>3600</td>
          </tr>
          <tr>
            <td>23</td>
            <td>1800</td>
          </tr>
          <tr>
            <td>21</td>
            <td>1080</td>
          </tr>
          <tr>
            <td>8</td>
            <td>720</td>
          </tr>
          <tr>
            <td>9</td>
            <td>360</td>
          </tr>
          <tr>
            <td>20</td>
            <td>306</td>
          </tr>
          <tr>
            <td>15</td>
            <td>180</td>
          </tr>
          <tr>
            <td>17</td>
            <td>180</td>
          </tr>
          <tr>
            <td>22</td>
            <td>144</td>
          </tr>
          <tr>
            <td>18</td>
            <td>119</td>
          </tr>
          <tr>
            <td>12</td>
            <td>108</td>
          </tr>
          <tr>
            <td>10</td>
            <td>80</td>
          </tr>
          <tr>
            <td>13</td>
            <td>72</td>
          </tr>
          <tr>
            <td>16</td>
            <td>72</td>
          </tr>
          <tr>
            <td>14</td>
            <td>54</td>
          </tr>
          <tr>
            <td>7</td>
            <td>36</td>
          </tr>
          <tr>
            <td>19</td>
            <td>36</td>
          </tr>
        </tbody>
      </table>


      <small class="text-white text-center">特別感謝</small>
    </div>
</template>


<script>
export default {
  name: 'App',
  data() {
    return {
      grid: Array(9).fill(0),
      numbers: Array.from({ length: 9 }, (_, i) => i + 1),
      selectedCells: [],
      gameStarted: false,
      isGameOver: false,
      revealedCell: null,
      lineSelected: false,
      selectedLine: null,
      showAll: false,
      prizeList: {
        6: 10000,
        7: 36,
        8: 720,
        9: 360,
        10: 80,
        11: 252,
        12: 108,
        13: 72,
        14: 54,
        15: 180,
        16: 72,
        17: 180,
        18: 119,
        19: 36,
        20: 306,
        21: 1080,
        22: 144,
        23: 1800,
        24: 3600
      },
      resultMessage: ''
    };
  },
  methods: {
    generateNumbers() {
      const numbers = Array.from({ length: 9 }, (_, i) => i + 1);
      for (let i = numbers.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [numbers[i], numbers[j]] = [numbers[j], numbers[i]];
      }
      return numbers;
    },
    startGame() {
      this.gameStarted = true;
      this.isGameOver = false;
      this.selectedCells = [];
      this.revealedCell = null;
      this.lineSelected = false;
      this.selectedLine = null;
      this.showAll = false;
      this.resultMessage = '';

      const numbers = this.generateNumbers();
      const randomIndex = Math.floor(Math.random() * 9);
      this.revealedCell = randomIndex;
      this.grid = Array(9).fill(0);
      this.grid[randomIndex] = numbers[randomIndex];
      this.numbers = numbers;
    },
    handleClick(index) {
      if (this.grid[index] === 0 && !this.isLocked(index) && this.selectedCells.length < 3) {
        const remainingNumbers = this.numbers.filter(num => !this.grid.includes(num));
        this.grid[index] = remainingNumbers[0];
        this.selectedCells.push(index);
        if (this.selectedCells.length === 3) {
          this.isGameOver = true;
        }
      }
    },
    revealAll() {
      if (this.isGameOver) {
        const remainingIndices = this.grid.map((value, index) => (value === 0 ? index : -1)).filter(index => index !== -1);
        const remainingNumbers = this.numbers.filter(num => !this.grid.includes(num));
        remainingIndices.forEach((index, i) => {
          this.grid[index] = remainingNumbers[i];
        });
        this.showAll = true;
      }
    },
    resetGame() {
      this.grid = Array(9).fill(0);
      this.numbers = Array.from({ length: 9 }, (_, i) => i + 1);
      this.selectedCells = [];
      this.gameStarted = false;
      this.isGameOver = false;
      this.revealedCell = null;
      this.lineSelected = false;
      this.selectedLine = null;
      this.showAll = false;
      this.resultMessage = '';
    },
    isLocked(index) {
      return !this.gameStarted || index === this.revealedCell;
    },
    selectLine(line) {
      if (this.isGameOver && !this.lineSelected) {
        this.lineSelected = true;
        this.selectedLine = line;
        this.revealAll();
        setTimeout(this.calculateResult, 100); // Delay to ensure all cells are revealed before calculating the result
      }
    },
    calculateResult() {
      let lineIndices;
      if (this.selectedLine === 'row1') {
        lineIndices = [0, 1, 2];
      } else if (this.selectedLine === 'row2') {
        lineIndices = [3, 4, 5];
      } else if (this.selectedLine === 'row3') {
        lineIndices = [6, 7, 8];
      } else if (this.selectedLine === 'col1') {
        lineIndices = [0, 3, 6];
      } else if (this.selectedLine === 'col2') {
        lineIndices = [1, 4, 7];
      } else if (this.selectedLine === 'col3') {
        lineIndices = [2, 5, 8];
      } else if (this.selectedLine === 'diag1') {
        lineIndices = [0, 4, 8];
      } else if (this.selectedLine === 'diag2') {
        lineIndices = [2, 4, 6];
      }

      // Calculate the score based on the selected line
      const score = lineIndices.reduce((sum, index) => sum + this.grid[index], 0);

      // Calculate prize based on the score
      const prize = this.prizeList[score] || 0;

      // Set the result message
      this.resultMessage = `你的的得分為 ${score}，獎金為 ${prize}。`;

      // Reveal all cells
      this.showAll = true;
      this.isGameOver = false;
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Kaisei+Decol&display=swap');

#app {
  text-align: center;
  margin-top: 20px;
}

.grid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.cell {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-family: 'Bebas Neue', sans-serif;
  color: #863030; /* 深紅色 */
  text-shadow: 1px 1px 0 #FFFFFF, -1px -1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF, 1px 1px 0 #FFFFFF; /* 白色外框 */
  cursor: pointer;
  user-select: none;
  background-image: url('@/assets/pot.png'); /* 設置背景圖片 */
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.cell.revealed {
  background-color: #f0f0f0; /* Revealed cells have a gray background */
}

.cell.selected {
  background-color: #d0f0d0; /* Selected cells have a green background */
}

.cell.computer-cell {
  background-color: #d3d3d3; /* Computer's revealed cell has a light gray background */
}

.cell.show-answer {
  background-color: #b0e0e6; /* Remaining cells revealed after "Reveal Result" have a light blue background */
}

.ffalert {
  background-image: url('@/assets/ffabg.png'); /* 設置背景圖片 */
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-position: center;
  color: white;
  border: 1px solid #95896b;
}

.line-buttons {
  display: grid;
  grid-template-columns: repeat(3, 100px); /* 與 .grid 的列寬一致 */
  gap: 10px; /* 與 .grid 的間距一致 */
  justify-content: center; /* 讓按鈕在 grid 內居中 */
}

.line-buttons button {
  width: 100%; /* 讓按鈕填滿其所在的 grid 單元格 */
  height: 30px; /* 根據需要調整高度 */
  padding: 0px;
  margin-bottom: 10px; /* 根據需要調整底部間距 */
}

.kaisei {
  font-family: "Kaisei Decol", serif;
  font-weight: 400;
  font-style: normal;
}


</style>
